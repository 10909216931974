<template>
  <div>
    <b-row class="mt-1 mb-2">
      <b-col>
        <b-button v-b-modal.modal-1 class="mb-1" @click="onCreateButton">
          Добавить
        </b-button>
        <b-modal id="modal-1" size="lg" :title="isUpdate ? 'Редактирование видео':'Загрузка видео'" hide-footer>
          <b-form size="sm" @submit.prevent="createVideo">
            <b-form-group label="Название:">
              <b-input v-model="form.name" required />
            </b-form-group>

            <b-form-group label="Видео:" label-cols-sm="4" label-cols-lg="4">
              <b-input-group>
                <b-form-input
                  v-if="!uploadSelected"
                  v-model="form.video"
                  readonly="readonly"
                  size="sm"
                />
                <b-form-file
                  ref="filebrowse"
                  v-model="videoFile"
                  size="sm"
                  :style="(uploadSelected && !uploadInProgress)?'':'display:none; visibility:hidden'"
                  browse-text="Выбрать"
                  :state="Boolean(videoFile)"
                  placeholder="Выберите файл"
                  drop-placeholder="Перетащите файл..."
                />
                <b-progress v-if="uploadInProgress" :value="percent_done" max="100" show-progress animated style="width: 100%;" />
                <b-input-group-append v-if="!uploadSelected">
                  <b-button size="sm" variant="info" @click="selectUpload">
                    <b-icon-upload />
                    Загрузить
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group v-if="validURL(form.video)">
              <video-player ref="videoPlayer" class="video-player-box" :options="playerOptions" />
            </b-form-group>


            <b-alert v-model="error" dismissible variant="danger">
              {{ message }}
            </b-alert>
            <loading-button :is-busy="isBusy" :text="isUpdate ? 'Сохранить':'Создать'" />
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
    <!-- table -->
    <div>
      <b-table
        ref="table"
        striped
        hover
        show-empty
        small
        :items="tableDataProvider"
        :fields="fields"
        :busy.sync="isBusy"
        empty-text="Нет видео"
        empty-filtered-text="Не найдено"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <!--        <template v-slot:cell(fio)="data">-->
        <!--          <router-link :to="{ name: 'Contact', params: { id: data.item.id }}">{{ contactFullName(data.item) }}</router-link>-->
        <!--        </template>-->
        <!--        <template v-slot:cell(company)="data">-->
        <!--          {{ data.item.company ? data.item.company.title : '' }}-->
        <!--        </template>-->
        <template v-slot:cell(url)="data">
          <a :href="data.value" target="_blank">{{ getPathName(data.value) }}</a>
        </template>
        <template v-slot:cell(date)="data">
          {{ new Date(data.item.date).toLocaleDateString(['ru-RU']) }}
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group>
            <b-button variant="primary" size="sm" @click="edit(data.item)">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" size="sm" @click="remove(data.item.id)">
              <b-icon icon="trash-fill" aria-hidden="true" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        align="center"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
      />
    </div>
  </div>
</template>

<script>
import LoadingButton from '../components/LoadingButton'
import 'video.js/dist/video-js.css'
import {videoPlayer} from 'vue-video-player'

export const part_size = 10 * 1024 * 1024 // чанки по 10 мб
export default {
  name: 'Video',
  components: {LoadingButton, videoPlayer},
  data() {
    return {
      isUpdate: false,

      videoFile: null,
      uploadSelected: false,
      uploadInProgress: false,
      reader: null,
      chunkNum: 1,
      parts: {Parts: []},
      key: '',
      upload_id: '',
      percent_done: 0,

      playerOptions: {
        fluid: true,
        muted: false,
        language: 'ru',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
      },
      searchQuery: {
        query: '',
        tags: [],
      },
      isBusy: false,
      isBusyCreate: false,
      totalRows: 1,
      errorResult: null,
      messageResult: '',
      perPage: 20,
      currentPage: 1,
      error: false,
      message: '',
      fields: [
        {key: 'id', label: '#'},
        {key: 'name', label: 'Название'},
        {key: 'url', label: 'Файл'},
        {key: 'date', label: 'Дата'},
        {key: 'actions', label: 'Действия'},
      ],
      items: [],
      form: {
        name: '',
        video: '',
        id: '',
      },
      isShowFilters: false,
    }
  },
  watch: {
    'form': {
      handler: function(data) {
        if (data && data.video) {
          let sources = this.playerOptions.sources
          if (this.validURL(data.video)) {
            if (sources.length === 0 || (sources[0] && sources[0].src !== data.video)) {
              this.playerOptions.sources = []
              this.playerOptions.sources.push({src: data.video})
            }
          }
        }
      },
      deep: true,
    },
    'videoFile':
      {
        handler: function(data) {
          if (data) {
            this.startUpload()
          }
        },
      },
  },
  methods: {
    getPathName(url){
      let el = document.createElement('a')
      el.href = url

      let result = el.pathname
      let pathname = el.pathname.split('/')
      if (pathname.length > 3) {
        pathname.splice(1, 1)
        result = pathname.join('/')
      }

      return result
    },
    onCreateButton() {
      this.isUpdate = false
      this.form.video = ''
      this.form.name = ''
      this.form.id = null
    },
    createVideo(evt) {
      evt.preventDefault()
      this.error = false
      this.isBusyCreate = true
      let promise
      if (this.isUpdate) {
        promise = this.axios.put(process.env.VUE_APP_BACKEND_URL + '/admin/api/videos', this.form, this.$store.getters.getAxiosConfig)
      } else {
        promise = this.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/videos', this.form, this.$store.getters.getAxiosConfig)
      }
      promise.then((response) => {
        this.isBusyCreate = false
        if (response) {
          if (response.data.error === false) {
            this.$refs.table.refresh()
            this.error = false
            this.$bvModal.hide('modal-1')
          } else {
            this.error = true
            this.message = response.data.message
          }
        }
      })
        .catch((error) => {
          console.log(error)
          this.isBusyCreate = false
        })
    },
    submitSearch(evt) {
      evt.preventDefault()
      this.$refs.table.refresh()
    },
    resetSearch(evt) {
      evt.preventDefault()
      this.searchQuery.query = ''
      this.searchQuery.tags = []
      this.$refs.table.refresh()
    },
    tableDataProvider(ctx) {
      const promise = this.axios.get(process.env.VUE_APP_BACKEND_URL + '/admin/api/videos?page=' + ctx.currentPage + '&perpage=' +
        ctx.perPage + '&query=' + this.searchQuery.query, this.$store.getters.getAxiosConfig)
      return promise.then(response => {
        this.items = response.data.videos
        this.totalRows = response.data.totalRows
        return this.items || []
      }).catch((error) => {
        console.log(error)
        this.items = []
        this.totalRows = 0
        return this.items || []
      })
    },
    remove(id) {
      if (confirm('Удалить видео #' + id + '?')) {
        this.error = false
        this.axios.delete(process.env.VUE_APP_BACKEND_URL + '/admin/api/videos/' + id, this.$store.getters.getAxiosConfig).then((response) => {
          if (response) {
            if (response.data.error === false) {
              this.error = false
              this.$refs.table.refresh()
            } else {
              console.log(response.data.message)
            }
          }
        })
      }
    },
    edit(item) {
      this.isUpdate = true
      this.form.id = item.id
      this.form.name = item.name
      this.form.video = item.url
      this.$bvModal.show('modal-1')
    },
    validURL(str) {
      let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
      return !!pattern.test(str)
    },
    selectUpload() {
      this.uploadSelected = true
      this.$refs.filebrowse.$el.childNodes[0].click()
    },
    startUpload() {
      this.reader = new FileReader()
      this.uploadInProgress = true
      this.percent_done = 1
      this.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/videos/upload/start', {file_name: this.videoFile.name}, this.$store.getters.getAxiosConfig)
        .then((response) => {
          if (response.data.error) {
            this.error = true
            this.message = response.data.message
            console.log(response.data.message)
            this.resetUpload()
          } else if (response.data.key) {
            this.key = response.data.key
            this.upload_id = response.data.upload_id
            this.upload_part(0)
            this.percent_done = 2
          }
        })
        .catch((reason) => {
          console.log(reason)
          this.resetUpload()
        })
    },
    upload_part: function(start) {
      const next_slice = start + part_size + 1
      const blob = this.videoFile.slice(start, next_slice)
      const self = this
      this.reader.onloadend = function(event) {
        if (event.target.readyState !== FileReader.DONE) {
          return
        }
        self.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/videos/upload/part', {
          file_data: event.target.result,
          file_name: self.videoFile.name,
          file_type: self.videoFile.type,
          chunk_num: self.chunkNum,
          parts: self.parts,
          upload_id: self.upload_id,
          key: self.key,
        }, self.$store.getters.getAxiosConfig)
          .then((response) => {
            if (response.data.error) {
              self.error = true
              self.message = response.data.message
              console.log(response.data.message)
              this.resetUpload()
            } else if (response.data.parts) {
              self.chunkNum = self.chunkNum + 1
              let size_done = start + part_size
              self.percent_done = Math.floor((size_done / self.videoFile.size) * 100)
              self.parts = response.data.parts
              if (next_slice < self.videoFile.size) {
                self.upload_part(next_slice)
              } else {
                self.finish_upload()
              }
            }
          })
          .catch((reason) => {
            console.log(reason)
            self.uploadInProgress = false
            self.resetUpload()
          })
      }

      this.reader.readAsDataURL(blob)
    },
    finish_upload: function() {
      this.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/videos/upload/finish', {
        file_name: this.videoFile.name,
        key: this.key,
        parts: this.parts,
        upload_id: this.upload_id,
      }, this.$store.getters.getAxiosConfig)
        .then((response) => {
          if (response.data.error) {
            this.error = true
            this.message = response.data.message
            console.log(response.data.message)
          } else if (response.data.url) {
            this.percent_done = 100
            this.form.video = response.data.url
          }
          this.resetUpload()
        })
    },
    resetUpload() {
      this.uploadInProgress = false
      this.percent_done = 0
      this.uploadSelected = false
      this.reader = null
      this.chunkNum = 1
      this.parts = {Parts: []}
      this.key = ''
      this.upload_id = ''
      this.percent_done = 0
      this.videoFile = null
    },

  },
}
</script>

<style scoped>

</style>
